// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	googleAnalyticsKey: 'UA-148064033-1',
	/*SENTRY_DSN: 'https://d6adc7de1c5b419eb098a82fd10956cb@sentry.dev.expertly.cloud/101',*/
	SENTRY_ENVIRONMENT: 'QA',
	SENTRY_RELEASE: '3.51.2',
	TRACES_SAMPLE_RATE: 1.0,
};

export const businessLogin = true;
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
